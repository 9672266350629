.np-container {
	padding-top: 4rem;
	margin-left: 2rem;
	padding-bottom: 5rem;
}

.np-section {
	/*border: 1px solid red;*/
	overflow: auto;
	justify-content: left;
}

.np-heading-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;

}

.np-heading {
	font-family: var(--font2);
	color: var(--text1);
	font-size: 2rem;
	margin: 0;
}

.np-page-nav{
	display: flex;
	flex-direction: row;
}

.np-page-nav-btn {
	margin: 0 .4rem;
}

.np-page-nav-btn {
	fill: var(--lightest);
}

.nav-btn-hidden {
	visibility: hidden;
}

/*.np-page-nav-btn:hover {
  background-color: var(--med-blue-2);
}

.np-page-nav-btn:active {
  background-color: var(--lt-blue);
}*/

.np-btn-container {
	/*width:  100%;*/
	/*display: flex;*/
	/*justify-content: center;*/
	padding-left: 14rem;
}

.add-asset-btn {
	font-family: "ciutadella-semibold";
	color: var(--text1);
	font-size: 1.2rem;
	padding:  0.8rem;
	width:  120px;
	text-align: center;
	margin: 1rem;
}

.np-subheading {
	font-family: "ciutadella-semibold";
	color: var(--text2);
	font-size: 1.6rem;
	margin-bottom: 0.5rem;
}

.np-subheading-2 {
	font-family: "ciutadella-semibold";
	color: var(--text3);
	font-size: 1.2rem;
}

.np-subheading-3 {
	font-family: "ciutadella-semibold";
	color: var(--text2);
	font-size: 1.2rem;
}

.np-text {
	max-width: 600px;
	text-align: justify;
	color: var(--text1);
	margin-top: 0.5rem;
	margin-bottom: 1rem;
}

.np-submit-btn {
	width: 200px;
}

.np-submit-btn h2 {
	color: var(--text1);
	font-family: var(--font2);
	padding: 1rem 2rem;
}

.np-data {
	display: flex;
	flex-direction: row;
}

.np-label {
	margin: .2rem 1rem;
	margin-left: 0.1rem;
	width: 28%;
	text-align: right;
	height: fit-content;
	color: var(--text3);
}

.np-unit-label {
	height: fit-content;
	margin:  0;
	text-align: left;
}

.np-input {
	width: 20%;
	min-width: 250px;
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: white;
	margin: .2rem;
	padding: 0.2rem;
	height: fit-content;
}

.np-text-area {
	min-height:  120px;
	font-family: var(--font1);
}

.np-input-label {
	color:var(--text3);
	font-weight: bold;
}

.input-table-inline-container {
	display: flex;
	direction: row;
}

/*.input-table-inline-container:first-child {
	margin-right: 4rem;
}*/

/* new project text styling */
.np-text-sec {
	background-color: var(--light05);
	padding: 0.5rem 1.5rem;
	margin-top: 1.5rem;
	width: 600px;
}

.np-text-highlight {
	color:  var(--text2);
}

.np-text-subsec-head {
	font-family: var(--font2);
	font-size: 1.4rem;
	font-weight: bold;
	color: var(--text1);
	margin-bottom: 0;

}

.np-text-ul {
	margin-top: 0.5rem;
	color: var(--text1);
	font-family: var(--font1);
}

/*help panel styling*/

.help-content {
	color: var(--text3);
	padding: 0 1.5rem;
	padding-bottom: 1rem;
}

.help-title {
	color: var(--text2);
	font-family: var(--font2);
}

.help-sub-title {
	color: var(--text1);
	font-family: var(--font2);
	font-size: 1.2rem;
	margin-bottom: .4rem;
}

.help-text {
	margin-right: 2rem;
	text-align: justify
}

.help-content table {
	background-color: var(--shade1);
	margin-bottom: 1rem;
	margin-right: 2rem;
}

.help-content table tr {
	border: 2px solid var(--bg1);
}

.help-col-header {
	color: var(--text2);
	font-family: var(--font2);
}

.help-var {
	font-weight: bold;
	padding: .6rem 1rem;
	text-align: right;
}

/*.help-def, .help-unit, .help-type {
	padding: .2rem .5rem;
}*/

.help-type {
	padding: .2rem .5rem;
}

.help-unit {
	padding: .2rem .5rem;
}

.help-def {
	text-align: justify;
	padding-right:  2rem;
}

.help-img {
	width: 100%;
	background-color: var(--light1);

}

.help-img img {
	width: 90%;
	margin: 1rem;
}

/*project checker formatting*/

.np-check-box {
	border: 3px solid var(--shade2);
	background-color: var(--shade1);
	padding: 1rem;
	font-family: monospace;
	font-size: 0.9rem;
	color: var(--text3);
}

.np-check-valid {
	color: var(--text2);
}

.np-check-warning {
	color: var(--orange);
}

.np-check-error {
	color: var(--red);
}

