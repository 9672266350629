/*results loading screen styling*/

.modal-container {
	/*border: 1px solid orange;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width:  100%;
	margin: 3rem;
}

.modal-div {
	border: 4px solid var(--light1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--light05);
	width:  fit-content;
	max-width: 800px;
	padding: 1rem 2rem;
}

.modal-icon {
	/*border: 1px solid white;*/
	display: flex;
	justify-content: center;
}

.modal-icon svg {
	fill: var(--text3);
	height: 4.2rem;
	width: 4.2rem;
}

.modal-label {
	/*border: 1px solid blue;*/
	color: var(--text3);
	font-family: var(--font2);
	font-size: 2rem;
	text-align: center;
	margin: 1rem 0;
}

.modal-text {
	/*border: 1px solid blue;*/
	font-family: var(--font1);
	font-size:  0.8rem;
	color:  var(--text3);
	max-width: 45ch;
	text-align: justify;
	margin: 0;
	padding:  0;
}

.modal-text p {
	margin:  0;
}

.modal-btn {
	width: fit-content;
	margin: 1rem;
}

.modal-btn-text {
	padding: 0.2rem 1rem;
	color: var(--text1);
}

@media (prefers-reduced-motion: no-preference) {
  .modal-icon-rotating svg {
    animation: modal-icon-rotating infinite 2s linear;
  }
}

@keyframes modal-icon-rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}