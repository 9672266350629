.apps-header-container {
	/*border: 1px solid red;*/
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top:  4rem;
	margin-bottom: 1rem;
}

.apps-title-h1 {
	/*border: 1px solid green;*/
	font-family: var(--font2);
	font-size:  3.5rem;
	color: var(--text1);
	margin:  0;

}

.apps-title-h2 {
	/*border: 1px solid blue;*/
	font-family: var(--font2);
	font-size: 1.8rem;
	color: var(--text2);
	margin:  0;
}
.apps-inline-logo-div {
	/*border: 1px solid white;*/
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.apps-logo-container {
	/*border: 1px solid blue;*/
	height: 4rem;
	width: 4rem;
	/*background: var(--bg2);*/
	display: flex;
	justify-content: center;
	align-items: center;
	/*border-radius: 50%;*/
	margin-right: .5rem;
	/*padding: 1rem;*/
}

.apps-logo-container img {
	height: 100%;
	width: 100%;
}