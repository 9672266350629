.admin-page-container {
  /*border: 1px solid blue;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  margin: 2rem 0;
  color: var(--text1);
}

.admin-menu-container {
  /*border: 1px solid green;*/
  max-width: 600px;
  padding: 1.5rem;
  background-color: var(--light05); 
  margin-top: 3rem; 
}

.admin-h2 {
  font-family: var(--font2);
  font-size: 1.8rem;
  margin: 1rem 0;
}

.admin-btn {
  padding: 0.1rem;
  margin: 0.7rem 0;
}

.admin-header-row-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
}

.admin-header {
  font-family: var(--font2);
  font-size: 2rem;
  margin: 0;
}

.admin-subheader {
  font-family: var(--font2);
  font-size: 1.5rem;
  color: var(--text2);
  margin: 0;
}


/* user list and cards */
.user-list-div {
  /*border: 1px solid blue;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  max-width: 1200px;
  min-width: 700px;
  width: fit-content;
  
  padding: 1.5rem;
  background-color: var(--light05);
}

.user-list-div-h2 {

}

.user-card {
  /*border: 1px solid blue;*/

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 0.5rem;

  padding: 0.5rem;
  background-color: var(--light05);
}

.user-card-data-section {
  flex: 4;  
  /*border: 1px solid green;*/
  display: flex;
  justify-content: center;
}

.user-card-btn-section {
  flex: 1;  
  display: flex;
  justify-content: center;
  /*border: 1px solid green;*/
}


.user-data-table {
  font-size: 0.8rem;
  width: calc(100% - 1rem);
}

.user-data-table-row {
  min-height: 1rem;
  width: 100%;
}

.user-data-label, .user-text-label {
  font-weight: bold;
  margin: 0.1rem;
  padding: 0.1rem;
  text-align: right;
}

.user-data-val, .user-text-val {
  background-color: var(--bg1);
  min-height: 1rem;
  margin: 0.1rem;
  padding: 0.1rem 1rem;
  text-align: center;
}

.user-text-label {
  text-align: center;
}

.user-text-val {
  height: 6.3rem;
  width: calc(100% - 1rem);
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.2rem 0.4rem;
  text-align: left;
  margin-right: 1rem;
}

.user-card-btn {
  padding: 0.3rem 0;
}

.user-card-btn-text {

}

/* admin data table (large) */
.admin-data-table {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.admin-data-table tr:nth-child(odd) {
  background-color: var(--shade05);
}

.admin-data-table tr:nth-child(even) {
  background-color: var(--light05);
}

.admin-data-table-header-icon {
  fill: var(--text2);
  height: 0.8rem;
  width: 1rem;
}

.admin-data-table tr th {
  font-weight: bold;
  color: var(--text2);
}

.admin-data-table tr th, .admin-data-table tr td {
  padding: 0.2rem 0.3rem;
}



/* admin small table (simple) */
.admin-small-table {
  font-size: 0.9rem;
}

.admin-small-table td {
  background-color: var(--shade15);
}

÷
.admin-small-table tr th {
  font-weight: bold;
  color: var(--text2);
}

.admin-small-table tr th, .admin-small-table tr td {
  padding: 0.2rem 1rem;
}


/* model status color styles */

.admin-model-status-failed  {
  color: var(--red);
}

.admin-model-status-queued  {
  color: var(--orange);
}

.admin-model-status-solved, .admin-model-status-complete {
  color: var(--blue1);
}



/* refresh button styling */
.admin-refresh-div{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width:  2.5rem;
  margin: 0;
  margin-left: 1rem;
}

.admin-refresh-div svg {
  fill:  var(--text1);
  height: 1.8rem;
  width:  1.8rem;
}

.admin-link-bar {
  display: flex;
  flex-direction: row;
/*  border: 1px solid red;*/
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}

.admin-link-btn {
  padding: 0.1rem;
  margin: 0.1rem 0.5rem;
  font-size: 0.8rem;
  width: 5rem;
}

