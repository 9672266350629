.update-list-container {
/*	border: 1px solid green;*/

	width: clamp(600px, 800px, 100px);

	margin: 2rem;
}

.update-list-container h2 {
	color: var(--text2);
	font-family: var(--font2);
	font-size: 1.7rem;

}

.update-container {

	background-color: var(--shade1);
	margin-bottom: 1rem;
	border-radius: 8px;
	padding: 0.5rem;

}

.update-header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-bottom: 2px solid var(--text2);
	color: var(--text2);
	font-weight: bold;

	padding: 0.2rem 0.5rem;
}

.update-date {
	margin-right: 1rem;
}

.update-title {

}

.update-body {
	color: var(--text1);
	text-align: justify;
	font-size: 0.9rem;
	padding: 0.5rem;
}
