:root{
  /*--bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;*/
  --nav-size: 50px;
  --sub-nav-size: 88px;
  --status-size: 2rem;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 

  --sideMargin1:  2rem;

  --font1:  'OpenSans';
  --font2: 'ciutadella-semibold';

  --red: #af0922;
  --orange: #f38118;
  --green: #008000;
  /*gray scales*/
  --lightest: #FFFFFF;
  --gray1: #EEEEEE;
  --gray1b: #e2e2e2;
  --gray1c: #d7d7d7;
  --gray2: #cccccc;
  --gray3: #a7a7a7;
  --gray4: #696969;
  --gray5: #494949;
  --gray6: #353535;
  --darkest: #111111;
  --black:  #000000;
  /*blues*/
  --blue1: #80c4e1;
  --blue2: #0777a7;
  --blue3: #215f9f;
  --blue4: #055476;
  --blue5: #04435e;
  --blue6: #02202d;
  
  /*shades*/
  --shade05:  rgba(0, 0, 0, 0.05) ;
  --shade1:  rgba(0, 0, 0, 0.1) ;
  --shade15:  rgba(0, 0, 0, 0.15) ;
  --shade2:  rgba(0, 0, 0, 0.2) ;
  --shade25:  rgba(0, 0, 0, 0.25) ;

  --light05:  rgba(255, 255, 255, 0.05) ;
  --light1:  rgba(255, 255, 255, 0.1) ;
  --light15:  rgba(255, 255, 255, 0.15) ;
  --light2:  rgba(255, 255, 255, 0.2) ;
  --light25:  rgba(255, 255, 255, 0.25) ;
  
  
}

.theme-dark {
  --bg1: var(--gray6);
  --bg2: var(--gray5);
  --bg3: var(--blue5);

  --text1:  var(--lightest);
  --text2: var(--blue1);
  --text3:  var(--gray2);
  --text-alert:  var(--orange);

  --btn1: var(--blue5);
  --btn1hover: var(--blue4);
  --btn1active: var(--blue2);

  --modelSolved: var(--blue2);
  --modelQueued: var(--gray3);
  --modelExp: var(--orange);
  --modelFailed: var(--red);
  --modelDel: var(--darkest);

  --icon1: var(--lightest);

  --icon-btn: var(--gray6);
  --icon-btn-hover: var(--gray5);
  --icon-btn-active: var(--gray4);
  --icon-svg: var(--lightest);
  --icon-svg-disabled: var(--bg2);
}

.theme-light {
  --bg1: var(--gray1c);
  --bg2: var(--gray2);
  --bg3: var(--blue3);

  --text1:  var(--darkest);
  --text2: var(--blue3);
  --text3:  var(--gray4);
  --text-alert:  var(--orange);

  --btn1: var(--blue4);
  --btn1hover: var(--blue2);
  --btn1active: var(--blue1);

  --modelSolved: var(--blue2);
  --modelQueued: var(--gray4);
  --modelExp: var(--orange);
  --modelFailed: var(--red);

  --icon1: var(--darkest);

  --icon-btn: var(--blue4);
  --icon-btn-hover: var(--blue2);
  --icon-btn-active: var(--blue1);
  --icon-svg: var(--lightest);
  --icon-svg-disabled: var(--blue3);
}

@font-face {
    font-family: "ciutadella-semibold";
    src: url("../../resources/fonts/Ciutadella Rounded/Ciutadella Rounded W04 SemiBd.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("../../resources/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../resources/fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "OpenSans";
    src: url("../../resources/fonts/OpenSans/OpenSans-Bold.ttf");
    font-weight: bold;
}



body {
  background-color: var(--bg1) !important;
  font-family: 'OpenSans';
}

.App {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--bg1);
}

/*.app-container {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}*/

.App-link {
  color: var(--blue1);
}

.page-container {
  width: 100%;
  overflow-y: hidden;
}

/*.sub-nav-container {
  width: 100%;
  margin-top: 4.5rem;
  background-color: var(--bg1);
  position: absolute;
  top: 64px;
  height:100%;
}*/

.page-scroll-container {
  width: 100%;
  height: calc(100vh - var(--nav-size) - var(--status-size));
  /*height: calc(80vh - 10px);*/
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: var(--nav-size);
  background-color: var(--bg1);
  /*border:  1px solid green;*/
}

.page-scroll-container-new-project {
  width: 100%;
  height: calc(100vh - var(--nav-size)  - var(--sub-nav-size) - var(--status-size));
  /*height: calc(80vh - 10px);*/
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: calc(var(--nav-size) + var(--sub-nav-size));
  background-color: var(--bg1);
  /*border:  1px solid green;*/
}

.page-fixed-container {
  width: 100%;
  height: calc(100vh - var(--nav-size) - var(--status-size));
  /*height: calc(80vh - 10px);*/
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: var(--nav-size);
  background-color: var(--bg1);
  /*border:  1px solid green;*/
}

.page-fixed-container-new-project {
  width: 100%;
  height: calc(100vh - var(--nav-size)  - var(--sub-nav-size) - var(--status-size));
  /*height: calc(80vh - 10px);*/
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: calc(var(--nav-size) + var(--sub-nav-size));
  background-color: var(--bg1);
  /*border:  1px solid green;*/
}

/*.scroll-sec-container {
  width: 100%;
  height: calc(100%-var(--nav-size)-var(--status-size));
  margin-top: 4.5rem;
  background-color: var(--darkest-gray);
}*/

.react-link {
  color: inherit;
  text-decoration: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

input[type="password"]
{
  -webkit-text-security: disc;
}

/*general styling for section title text*/

.sec-title-h1 {
  font-family: "ciutadella-semibold";
  color: var(--text2);
  font-size: 2rem;
}

.sec-title-h2 {
  font-family: "ciutadella-semibold";
  color: var(--text3);
  font-size: 1.6rem;
}

.sec-text {
  /*background-color: var(--dark-gray);*/
  color: var(--text1);
  margin: 0 4rem;
  /*max-width: 800px;*/
  max-width: 60ch;
  text-align: justify;
}

.text-emph {
  font-weight: bold;
  color: var(--text2);
}

.text-section {
  padding-bottom: 1.2rem;
}

/*general styling for buttons*/

.dark-btn {
  font-family: "ciutadella-semibold";
  background: var(--btn1);
  cursor: pointer;
  border-radius: .4rem;
}

.dark-btn h2{
  font-size: 1.2rem;
  margin: .4rem;
}

.dark-btn:hover {
  background-color: var(--btn1hover);
}

.dark-btn:active {
  background-color: var(--btn1active);
}

/*generic dash menu styling*/

.dash-menu-container {
  display: flex;
  flex-direction: column;
  /*align-content: center;*/
  align-items: center;
  text-align: center;
}

.dash-sub-head {
  margin: .5rem;
}

.dash-sub-container {
  background-color: var(--bg2);
  /*border: 1px solid red;*/

  width: 360px;
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.dash-sub-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*border: 1px solid blue;*/
  color: white;
}

.dash-btn {
  margin: .5rem;
  width: 70%;
}

/* text button */
.text-button {
  font-family: "ciutadella-semibold";
  background: var(--btn1);
  cursor: pointer;
  border-radius: .4rem;
}

.text-button-disabled {
  font-family: "ciutadella-semibold";
  background: var(--bg1) !important;
  color: var(--bg2) !important;
  cursor: default !important;
  border-radius: .4rem;
}

.text-button h2{
  font-size: 1.2rem;
  margin: .4rem;
  text-align: center;
}

.text-button:hover {
  background-color: var(--btn1hover);
}

.text-button:active {
  background-color: var(--btn1active);
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--icon-btn);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button svg {
  fill: var(--icon-svg);
}

.disabled-icon-button svg {
  fill: var(--icon-svg-disabled);
}

.icon-button:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.display-none {
  display: none !important;
}

/* generic flex containers */

.centered-col-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
/*  border: 1px solid pink;*/
}

.centered-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
/*  border: 1px solid blue;*/
}