.proj-menu-container {
/*	border: 1px solid green;*/
	width: clamp(600px, 780px, 800px);
	padding-top: 2rem;
}

.proj-container {
	padding-top: 4rem;
	margin-left: 2rem;
}

.proj-section {
	border: 4px solid var(--shade05);;
	padding: 1rem 0.5rem;
	margin-bottom: 2rem;
	background-color: var(--shade05);
	border-radius: 5px;
}

.proj-heading-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.proj-heading {
	font-family: "ciutadella-semibold";
	color: var(--text1);
	font-size: 2rem;
	margin: 0;
}

.proj-section-header {
	font-family: "ciutadella-semibold";
	color: var(--text1);
	font-size: 1.4rem;
	margin: 0;
	margin-top: 0.5rem;
}

.proj-list-header{
	font-family: "ciutadella-semibold";
	color: var(--lightest);
	font-size: 2rem;
	margin:  0;
}

.proj-row-div {
	/*border: 1px solid green;*/
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem;
	margin-top:  0;
}

.proj-list-container{
	width: 90%;
}

.proj-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: var(--dark-gray);
	padding: 0 1rem;
	margin: .7rem 0;
}

.proj-item {
	font-weight: normal;
	font-size: .9rem;
	display: flex;
}

.proj-name {
	flex-grow: 1.5;
	/*border: 1px solid blue;*/
}

.proj-date {
	flex-grow: 1;
	/*border: 1px solid orange;*/
}


.proj-new-button {

  margin: .5rem;
  color: white;
  border-radius: .4rem;
  padding: .1rem .5rem;
  text-align: center;
  width: 200px;
  margin: 0.5rem 2rem;
}

.proj-new-button h2{
  font-size: 1.4rem;
  margin: .4rem;
}

/*refresh icon & button*/
.proj-refresh-div{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	margin: 0;
	margin-left: 1rem;
	padding: 0 0.5rem;
}

.proj-refresh-div svg {
	fill:  var(--text1);
	height: 1.3rem;
	width:  1.3rem;
}

.proj-refresh-div h2 {
	color: var(--text1);
	font-family: var(--font2);
}