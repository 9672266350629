.rdt-doc-container {
	margin: 0 2rem;
}

.rdt-doc-sec {
	background-color:  var(--light05);
	margin:  1rem 0;
	padding:  0.3rem 2rem;
	/*border: 3px solid var(--light1);*/
	width:  fit-content;
}

.rdt-doc-h2 {
	color:  var(--text2);
	font-family: var(--font2);
	margin: 0.7rem 0;
}

.rdt-doc-text {
	color:  var(--text1);
	font-family: var(--font1);
	text-align: justify;
	margin-top: 0;
	max-width: 60ch;
}

.rdt-doc-link {
	color:  var(--text2);
}

.rdt-doc-h4{
	color:  var(--text1);
	font-family: var(--font2);
	font-size: 1.3rem;
	margin-top: 1.4rem;
	margin-bottom:  0.2rem;
}

