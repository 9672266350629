/*API user info formatting*/
.api-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: center;
	padding-top: 1rem;
	max-width: 800px;
	margin-bottom: 2rem;
	margin-left: 4rem;

/*	border: 1px solid orange;*/
}

.api-section-header {
	font-family: "ciutadella-semibold";
	color: var(--text2);
	margin: 0;
	margin-bottom: 1rem;
}

.confirm-title {
	font-family: "ciutadella-semibold";
	color: var(--text2);
	font-size: 1.8rem;
	/*border: 1px solid orange;*/
	margin:0;
	margin-bottom: 0.2rem;
}

.api-table {
	color: var(--text1);
	/*border: 1px solid green;*/
	/*width: 600px;*/
	border-collapse: separate;
    border-spacing: 0 0.4rem;

}

.api-table tr {
	margin: 20px;
}

.api-field { 
	text-align: right;
	padding-right: .4rem;
}
.api-value {
	padding: 0 3rem;
	background-color: var(--bg2);
}


/*API documentation formatting*/
.doc-container {
	width: 100%;
}

.api-header {
	margin: 0 4rem;
}

.api-header h1, .api-header h2,{
	text-align: left;
}

.api-user-text {
	color: var(--text1);
	margin: 0;
	max-width: 900px;
	text-align: justify;
}

.doc-text {
	/*background-color: var(--dark-gray);*/
	color: var(--text1);
	margin: 0 4rem;
/*	padding-top: 4rem;*/
	/*padding: 5rem;*/
	/*overflow: auto;
	width: 90%;*/
	padding-bottom: 5rem;
	max-width: 900px;
	text-align: justify;
}

.doc-sec {
/*	padding-top: 2.6rem;*/
}

.doc-content-header {
	margin-bottom: 0;
}

.doc-code-block{
	background-color: var(--bg2);
	padding:0.5rem 1rem;
}

.doc-text h1, .doc-text h2, .doc-text h3 , .doc-contents-list li {
	font-family: "ciutadella-semibold";
	color: var(--text2);
}

.doc-text h1 {
	font-size: 1.7rem;
	margin-bottom: .2rem;
}

.doc-text h2 {
	font-size: 1.5rem;
	margin-bottom: .2rem;
}

.doc-text h3 {
	font-size: 1.4rem;
	margin-bottom: .2rem;
}

.doc-text h4 {
	margin-bottom: .2rem;
}

.doc-text p {
	margin-top: .7rem;
	max-width:  60ch;
}

.code-comment {
	color: var(--text2);
}

.doc-contents-list {
	margin: 0;
	padding-left: 1.4rem;
}

.doc-contents-list li{
	cursor: pointer;
	font-size: 1.3rem;
	/*list-style-type: none;*/
}

.doc-contents-list li a {
	color: inherit;
	text-decoration: inherit;
}

.doc-subsec-title {
	font-family: "ciutadella-semibold";
	font-size: 1.2rem;
	color: var(--text3);
}

/* download section styleing */

.api-dl-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

}

.api-dl-btn {
	width: 250px;
	margin-bottom: 0.8rem;
}

.api-dl-btn h2{
	color: var(--text1);
	font-size: 1rem;
}

