	.rdt-container {
		margin-top: 4rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.rdt-header-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.rdt-title-h1 {
		/*border: 1px solid green;*/
		font-family: var(--font2);
		font-size:  3rem;
		color: var(--text2);
		margin:  0;

	}

	.rdt-title-h2 {
		/*border: 1px solid blue;*/
		font-family: var(--font2);
		color: var(--text2);
		margin:  0;
		margin-bottom: 0.5rem;
	}

	.rdt-logo-container {
		height: 5rem;
		width: 5rem;
		background: var(--bg2);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin-top: .5rem;
		/*padding: 1rem;*/
	}

	.rdt-logo-container img {
		height: 100%;
		width: 100%;
	}

/*	.rdt-logo-container svg{
		fill: var(--bg1);
		height: 100%;
		width: 100%;
	}*/