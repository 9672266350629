.res-inv-header{
	font-family: var(--font2);
	color: var(--text1);
	margin: 0;
	text-align: center;
}

.res-inv-report-div {
	/*border:  1px solid red;*/
	background-color:  var(--shade05);
	width:  fit-content;
	padding: 1rem 2rem;
	margin-bottom:  2rem;
}

.res-inv-der-header {
	font-family: var(--font2);
	color: var(--text2);
	margin-bottom: 0;
	font-size: 1.2rem;
}

.res-inv-data-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	/*border: 1px solid red;*/
}

.res-inv-metric-label {
	width: 110px;
	text-align: right;
	font-family: var(--font2);
	color: var(--text3);
	padding-right: .5rem;
	margin: 0.2rem;
}

.res-inv-metric-data {
	min-width: 120px;
	text-align: center;
	background-color: var(--bg2);
	color: var(--text1);
	padding: 0.2 2rem;
	margin: 0.2rem;
}

.res-inv-metric-unit {
	padding-left: .5rem;
	font-family: var(--font2);
	color: var(--text3);
	margin: 0.2rem;
}
