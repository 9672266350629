.res-table-container {
	/*border: 1px solid blue;*/
	padding-bottom: 2rem;
	display: flex;
	justify-content: center;
}

.res-table {
	/*border: 1px solid red;*/
}

.res-table-title {
	font-family: var(--font2);
	font-size: 1.5rem;
	color: var(--text2);
	/*border: 1px solid red;*/
	margin-bottom: .2rem;
	text-align: center;
}

.res-table-title th {
	/*colspan: 100%*/
}

.res-table-header-row {
	font-family: var(--font2);
	font-size: 1.2rem;
	color: var(--text3);
}

.res-table-header-row th {
	padding: .1rem .6rem;
	/*border: 1px solid green;*/
}

.res-table-row-label {
	font-family: var(--font2);
	font-size: 1.2rem;
	color: var(--text3);
	text-align: right;
}

.res-table-data-row {

}

.res-table-data {
	background-color: var(--bg2);
	color: var(--text1);
	text-align: center;
	/*border: 1.5px solid var(--bg1);*/
	padding: .1rem 2rem;
}

.res-table-unit {
	color: var(--text2);
}
