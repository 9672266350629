.model-card {
	display: flex;
	flex-direction: column;
	/*justify-content: space-between;*/
	/*border: 1px solid red;*/
	width: calc(100% - 2rem - var(--model-border));
	/*max-width: 800px;*/
	/*width:  100%;*/
	margin: .4rem 1rem;
	padding: 0;
	background: var(--light05)
}

.model-card:first-child {
	margin-top:  1rem;
}

.model-card:last-child {
	margin-bottom:  1rem;
}

.model-bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
}

.model-bar:hover {
	background: var(--light1)
}

.model-bar-left {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.model-bar-right {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.model-bar-status-color {
	min-width: .5rem;
	margin-right: .2rem;
	height: 100%;
}

.model-bar-segment{
	flex-grow: 1;
}

.model-bar-data {
	margin: 0.7rem 0.2rem;
	margin-right:  1rem;
	font-family: "ciutadella-semibold";
  color: var(--text3);
  /*font-size: 1rem;*/
}

.model-content  {
	padding: 1rem 2rem;
	border-top:  3px  solid var(--bg1);
}

.model-content-table {
	/*border:  1px solid red;*/
}

.model-content-row {
	/*border:  1px solid blue;*/
	display: flex;
	flex-direction: row;
}

.model-content-header {
	font-family: "ciutadella-semibold";
  color: var(--text3);
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;

}

.model-content-label {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-family: "ciutadella-semibold";
  color: var(--text3);
  width: clamp(150px, 170px, 200px); 
  /*width: 120px; */
  /*border: 1px solid green;*/
  margin: 0.2rem;
}

.model-content-data {
	background-color: var(--bg1);
  color: var(--text3);
  margin: 0.2rem;
  margin-left: 0.5rem;
  padding: 0.2rem;
  text-align: center;
  width: clamp(200px, 60%, 600px); 
	/*border: 3px soldi var(--dark-gray);*/
}

/*model card help text*/
.model-help-section {
	/*border:  1px solid red;*/
	margin-top: 2rem;
}

.model-help-text {
	/*border:  1px solid green;*/
	color:  var(--text3);
	font-size: 0.8rem;
	margin: 0;
}


/*card buttons and button containers*/
.model-card-btn-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-top: 2rem;
}

.model-card-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 130px;
	text-align: center;
	padding: .2rem;
	margin-top: 1rem;
	color: var(--lightest);
	border: 4px solid transparent;
}

.disabled-card-btn {
	background-color: var(--bg2);
	border: 4px solid var(--bg1);
	color: var(--bg1);
	cursor: default;
}

.disabled-card-btn:hover {
	background-color: var(--bg2);
}

/*model card toggle icon*/

.model-details-toggle-btn {
	display: flex;
	align-items: center;
}
.model-details-toggle-btn svg {
	height:  1rem;
	width:  1rem;
	fill: var(--text3);
	margin: 0 0.2rem;
}


/* color left-border on model list toggle btn*/

.model-status-solved {
	border-left:var(--model-border) solid var(--modelSolved);
}

.model-status-queued {
	border-left:var(--model-border) solid var(--modelQueued);
}

.model-status-failed {
	border-left:var(--model-border) solid var(--modelFailed);
}

.model-status-expired {
	border-left:var(--model-border) solid var(--modelExp);
}

.model-status-deleted {
	border-left:var(--model-border) solid var(--modelDel);
}