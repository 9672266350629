/*HELP toggle container styling*/

.np-help-toggle-section {
	display: flex;
	flex-direction: column;
	/*border: 1px solid var(--bg2);*/
	margin-top: 0;
	margin-bottom: 1rem;
	margin-right: var(--sideMargin1);
}

.np-help-toggle-section-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	/*background-color: var(--shade1);*/
	padding-bottom: .5rem;
	cursor: default;
}

.np-help-toggle-section-content {
	padding-left: 1rem;
	background-color: var(--shade1);
}

.np-help-toggle-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: var(--light05);
	padding: 0.3rem;
	min-width: 140px;
	border-radius: 7%;
}

.np-help-toggle-btn:hover {
	background-color: var(--light1);
}

.np-help-toggle-icon {
	/*border: 1px solid red;*/
	border: 2px solid var(--text3);
	height: 1.2rem;
	width: 1.2rem;
	border-radius: 50%;
	/*background-color: var(--shade1);*/
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 0.5rem;
}

.np-help-toggle-btn svg {
	height: 90%;
	width: 90%;
	fill:  var(--text3);
	padding: 0;
}

.np-help-toggle-section-label {
	/*border: 1px solid red;*/
	color: var(--text3);
	font-family: var(--font1);
	font-size: 1.2rem;
	margin: 0;
	padding: 0;
	padding-right: 0.3rem;
}

/*----
STYLING FOR HELP BOX TYPE 2
----*/

.np-help2-toggle-section {
	display: flex;
	flex-direction: column;
	/*border: 1px solid pink;*/
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.np-help2-toggle-section-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: var(--shade1);
	/*padding-bottom: .5rem;*/
	cursor: default;
	width: 100%;
}

.np-help2-toggle-section-content {
	padding-left: 1rem; 
	background-color: var(--shade1);
}

.np-help2-toggle-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	cursor: pointer;
	background-color: var(--light05);
	padding: 0.3rem;
	padding-left: 1rem;
	min-width: 140px;
	width: 100%;
	/*border-radius: 7%;*/
}

.np-help2-toggle-btn:hover {
	background-color: var(--light1);
}

.np-help2-toggle-icon {
	/*border: 1px solid red;*/
	border: 1px solid var(--text3);
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	/*background-color: var(--shade1);*/
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 0.5rem;
}

.np-help2-toggle-btn svg {
	height: 90%;
	width: 90%;
	fill:  var(--text3);
	padding: 0;
}

.np-help2-toggle-section-label {
	/*border: 1px solid red;*/
	color: var(--text3);
	font-family: var(--font1);
	font-size: 0.9rem;
	margin: 0;
	padding: 0;
	padding-right: 0.3rem;
}

