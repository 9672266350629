.results-container{
	max-width: 1000px;
	min-width: 800px;
	margin-top: 2rem;
}

.results-section {
	/*border: 1px solid red;*/
	margin-bottom: 2rem;
	margin-left: 2rem;
	padding: 1rem 0 2rem 2rem;
	background-color: var(--light05);
	height: fit-content;

}

.results-subsection {
	margin-top: 2rem;
	/*border: 1px solid red;*/
}

.results-heading {
	color: var(--text1);
	font-family: var(--font2);
	font-size: 1.8rem;
	margin-top: 0;
}

.results-heading-2 {
	color: var(--text2);
	font-family: var(--font2);
	font-size: 1.4rem;
}

.results-placeholder {
	background-color: var(--bg2);
	width: 80%;
	/*border: 1px solid orange;*/
	padding:  1rem;
}

.results-placeholder-text {
	color: orange;
}

.results-row-container {
	display: flex;
	flex-direction: row;
	justify-content: left;
}

.results-row-container div {
	margin-right: 0.5rem;
}






/* model results summary table */

.res-sum-table {

}

.res-sum-data-row {

}



.res-sum-data{
	background-color: var(--bg2);
	text-align: center;
	width: 50ch;
	color: var(--text1);
	margin: 0.1rem 0;
	padding:  0.15rem 0.3rem;
	word-wrap: break-word
}

.res-sum-data-label {
	color: var(--text3);
	font-family: var(--font2);
	text-align: right;
}

.res-dl-btn-container {
	/*border: 1px solid green;*/
	max-width: 24ch;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.res-dl-btn {
	font-family: var(--font2);
	color: var(--text1);
	width: 18ch;
	margin: 0.5rem 0;
	padding: 1rem;
	text-align: center;
}




/* results status summary table and section */

.res-status-table {

}

.res-status-header-row {
	color: var(--text3);
	font-family: var( --font2 );
}

.res-status-header-row th {
	padding: 0 0.5rem;
}

.res-status-data-row {

}

.res-status-container {
	display: flex;
	align-items: center;
	justify-content: left;
	/*border: 1px solid red;*/
}

.res-status-data{
	background-color: var(--bg2);
	text-align: center;
	min-width: 120px;
	/*border: 2px solid var(--bg1);*/
	color: var(--text1);
}

.res-status-data-label {
	color: var(--text3);
	font-family: var(--font2);
	text-align: right;
}

.res-status-solved {
	fill: var(--blue2);
	height: 1.5rem;
	width: 1.5rem;
	padding: 0 0.3rem;
}

.res-status-infeasible {
	fill: var(--orange);
	height: 1.5rem;
	width: 1.5rem;
	padding: 0 0.3rem;
}

.res-status-failed {
	fill: var(--red);
	height: 1.5rem;
	width: 1.5rem;
	padding: 0 0.3rem;
}

.res-status-container p {
	margin: 0.3rem 0;
}

.results-note {
	max-width: 60ch;
	color:  var(--text3);
	text-align: justify;
	font-size:  0.9rem;
	padding-top: 1rem;
}

.results-note-table {
	color:  var(--text3);
	text-align: justify;
	font-size:  0.9rem;
	padding: 1rem;
}







/*results chart styling*/

.results-chart-container {
	height: 24rem;
	width: 90%;
	/*border: 1px solid green;*/
	padding: 1rem;
	padding-bottom: 2rem;
}



/*select drop-down styling*/
.res-select-container {
	/*border:  1px solid purple;*/
	padding: .3rem 0;
	display: flex;
	flex-direction: row;
}

.res-select-label-container {
	text-align: right;
	/*border:  1px solid purple;*/
}

.res-select-label {
	font-family: var(--font2);
	color: var(--text3);
	padding-right: 1rem;
}

.res-select {
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: white;
	cursor: pointer;
	padding: .1rem;
}

.res-select-multi-container {
	display: flex;
	flex-direction: row;
}

.res-select-multi-container {
	display: flex;
	flex-direction: row;
}

.res-select-multi-container > .res-select-container {
  margin-right: 2rem;
}

.chart-title {
	/*border: 1px solid pink;*/
	margin: 0;
	text-align: center;
	color: var(--text3);
	font-family: var(--font2);
	font-size: 1.5rem;
}

.res-download-btn {
	font-family: var(--font2);
	color: var(--text1);
	max-width: 200px;
	margin-top: 2rem;
	padding: 0.5rem 1rem;
	text-align: center;
}

.res-ts-scen-label-text {
	color: var(--text1);
	font-family: var(--font2);
	text-align: center;
}


/*results loading screen styling*/

.results-loading-container {
	/*border: 1px solid orange;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width:  100%;
	margin-top: 3rem;
}

.results-loading-modal {
	/*border: 1px solid green;*/
	background-color: var(--light05);
	width:  fit-content;
	max-width: 800px;
	padding: 1rem;
}

.results-loading-icon-container {
	/*border: 1px solid white;*/
	display: flex;
	justify-content: center;
}

.results-loading-icon-container svg {
	fill: var(--text3);
	height: 4.2rem;
	width: 4.2rem;
}

.results-loading-text {
	/*border: 1px solid blue;*/
	color: var(--text3);
	font-family: var(--font2);
	font-size: 2rem;
	text-align: center;
	margin: 1rem 0;
}

@media (prefers-reduced-motion: no-preference) {
  .results-loading-icon-container svg {
    animation: results-loading-spin infinite 2s linear;
  }
}

@keyframes results-loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*empty ts section warning styling*/
.results-ts-empty {
	border: 6px solid var(--light2);
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
}

.results-ts-empty svg{
	fill: var(--light2);
	width: 4rem;
	height: 4rem;
	padding: 0;
}

.results-ts-empty h4{
	color: var(--text3);
	text-align: center;
	padding: 0;
	margin: 0;
	margin-bottom: 1rem;
}





