.terms-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  /*background-color: rgba(0,0,0, 0.5);*/
}

.terms-popup-inner {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 15%;
  bottom: 10%;
  margin: auto;
  background: var(--bg2);
  overflow-x: hidden;
  overflow-y: auto;
}

.terms-heading {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  background: var(--light1);

  border-radius: 6px 6px 0 0;
}

.terms-heading h2{
  font-family: "ciutadella-semibold";
  color: var(--text2);
  /*border: 1px solid red;*/
  margin: .6rem;
}

.hide-terms svg {
  fill: var(--text2);
  height: 1.3rem;
  width: 1.3rem;
  margin: .6rem;
  cursor: pointer;
}

.hide-terms svg:hover {
  fill: var(--text2);
}

.terms-text {
  margin: 1rem 2rem;
  color:  var(--text3);
}

.terms-emph {
  font-weight: bold;
}