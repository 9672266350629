.asset-list-section{
	/*border:  1px solid red;*/
	overflow-x: auto;
	width:  100%;
	scrollbar-color: var(--bg2) var(--bg1);
	/*scrollbar-color: dark;*/
}

.asset-list-table{
	/*border:  1px solid blue;*/
	margin:  2rem 0.2rem;
	margin-top: 0;
	white-space: nowrap;
}

/*.asset-list-table tr td{
	width:  fit-content;
}*/

.asset-list-header{
	/*border:  1px solid blue;*/
	margin:  0;
	font-size:  1.25rem;
	color: var(--text3);
}

.empty-asset-list-text {
	color: var(--text3);
	text-align: center;
	background-color: var(--bg2);
	max-width: 200px;
	padding: .5rem 2rem;
}

.asset-label-row{
	/*border:  1px solid green;*/

}

.asset-label-text{
	/*border:  1px solid orange;*/
	padding: 0.2rem 0.5rem;
	color: var(--text2);
}

.asset-data-row{
	/*border:  1px solid purple;*/

}

.asset-data-text{
	/*border:  1px solid yellow;*/
	padding: 0.2rem 1rem;
	background-color: var(--bg2);
	color:  var(--text1);
	text-align: center;
}

.asset-btn {
	color:  var(--text1);
	width:  4rem;
	padding:  0.2rem;
	text-align: center;
}
