.nav-container {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--lightest);
  background-color: var(--bg2);
  position: fixed;
  height: var(--nav-size);
  z-index:9999;
}

.nav-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
}

.nav-logo {
  height: 2.7rem;
  width: 2.7rem;
  margin: .3rem;
}

.nav-logo img {
  width: 100%;
}

.nav-header-text h1{
  margin: .1rem;
  font-family: "ciutadella-semibold";
  font-size: 2.2rem;
}

.nav-sub-head {
  font-size: 1.7rem;
  color: var(--text2);
}

.nav-menu-button-container {
  display: flex;
  flex: row;
}


.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--bg1);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  cursor: pointer;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: var(--icon-btn-hover);
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
