.home-container {
  text-align: center;
  background-color: var(--bg2);
  /*border: 1px solid red;*/
  margin-top: 5rem;
  
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.2rem 0.6rem;
  /*width: 400px;*/
  /*height: auto;*/
  height: 250px;
}

.home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "ciutadella-semibold";
  font-size: calc(10px + 2vmin);
  color: white;
  /*border: 1px solid green;*/
}

.home-header-text {
  /*border: 1px solid white;*/
}

.home-header-text h1{
  font-size: 3.5rem;
  margin: .1rem;
}

.home-header-logo {
  margin: 0;
  /*margin-right: .rem;*/
  /*border: 1px solid blue;*/
}

.home-header-logo img {
  height: 4rem;
  pointer-events: none;
}

.home-sub-head {
  font-size: 2.5rem;
  color: var(--text2);
}

.home-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*border: 1px solid blue;*/
  color: white;
}



.home-menu-button {
  background: var(--btn1);
  margin: .5rem;
  cursor: pointer;
  width: 60%;
  border-radius: .4rem;
}

.home-menu-button:first-child {
  margin-top: .2rem;
}

.home-menu-button:last-child {
  margin-bottom: 0;
}

.home-menu-button h2{
  font-size: 1.2rem;
  margin: .4rem;
}

.home-menu-button:hover {
  /*border: 1px solid var(--lt-blue);*/
  background-color: var(--btn1hover);
}

.home-menu-button:active {
  background-color: var(--btn1active);
}

.home-footer {}

.home-footer, .home-footer p {
  font-size: .8rem;
  margin-bottom: .5rem;
  margin-top: 1rem;
  color: var(--text3);
  /*border: 1px solid orange;*/
}

/*@media (prefers-reduced-motion: no-preference) {
  .home-header-logo-img {
    animation: header-logo-spin infinite 20s linear;
  }
}*/


@keyframes header-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}