.node-page-container {
/* div for all node editing elements*/
  width: 100%;
}

.node-sand-box-container {

	margin-top: 1rem;
	padding-left: 2rem;

}


.node-sand-box {

	border: 2px solid var(--light2);
	background-color: var(--light05);

	/*min-width: 600px;
	min-height: 600px;

	max-width: 800px;
	max-height: 800px;*/

	height: clamp(400px, 500px, 600px);
	width: clamp(600px, 800px, 900px);

	overflow-x: auto;

	position: relative;

}

.node-sand-box-header {
	font-family: var(--font2);
	color: var(--text2);
	font-size: 1.8rem;
	margin: 0.5rem 0;
}

.node-sand-box-subheader {
	font-family: var(--font2);
	color: var(--text2);
	font-size: 1.2rem;
	margin: 0 0.5rem;
}


/* node object styling */
.node-obj {
	position: absolute;

	background-color: var(--shade1);
	width: 76px;
	height: 46px;

	padding: 0.5rem;

	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 3px;

}

.node-obj:active:hover {
	cursor: move;

}


.node-obj-active {
	cursor: grab;
	border: 2px solid var(--text2);
}

.node-obj-inactive {
	cursor: pointer;
	border: 2px solid var(--light1);
}

.node-overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
}

.node-obj h4 {
	color: var(--text3);
	font-size: 0.7rem;
	margin:0;
	text-align: left;
	width: 100%;
}

.node-obj svg {
	fill: var(--text3) !important;
	margin:0;
}

/*connection styling*/

.node-line {
	stroke: var(--light1);
	position: absolute;
	overflow: visible;
	z-index: 1;
}

.node-line-htpipe {
	stroke: #8c001c;
	stroke-opacity: 0.4;
}

.node-line-ltpipe {
	stroke: #d9ad00;	
	stroke-opacity: 0.4;

}

.node-line polyline:hover {
	cursor: pointer;
}

/* menu and inputs styling */

.node-menu-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.node-menu-btn-row {
	display: flex;
	flex-direction: row;
	padding-left: 12rem;
}

.node-menu-box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.node-menu-container {
	margin-top: 1rem;
	margin-left: 2rem;
}

.node-menu-section {
	margin: 1rem;
	margin-left: 0;
	padding: 0.5rem;
	border: 2px solid var(--light2);
	background-color: var(--light05);
}

.node-menu-top-btn {
	width: 13ch !important;
}

.node-menu-btn {
	width: 19ch;
	color: var(--text1);
	padding: 0.2rem;
	margin: 0 0.5rem;
}

.node-menu-btn h2{
	font-size: 1rem;
	margin: 0.2rem 0.4rem;
}

.node-menu-btn-small {
	width: 3ch !important;
}

.node-menu-btn-small h2{
	font-size: 1.2rem;
	margin: 0;
}

.node-menu-table {
/*	background-color: var(--shade05);*/
	color: var(--text3);
	margin: 0.5rem;
	font-size: 0.9rem;
}

.node-menu-table th{
	font-weight: bold;
	text-align: center;
/*	padding-right: 1rem;*/
}

.node-menu-table td, .node-menu-table th{
/*	border: 1px solid blue;*/
}

.node-line-table-data{
	text-align: center;
	color: var(--text2);
	padding-left: 1rem;
}


/* msg pane */

.node-menu-msg {
	border: 2px solid var(--light2);
	background-color: var(--light05);
	height: 1.5rem;
	width: 800px;
	margin-bottom: 0.1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.node-menu-msg p {
	margin: 0;
	margin-left: 0.5rem;
	color: var(--text3);
	font-family: monospace;
	font-size: 1rem;
}

/* select and input styling */
.node-input {
	background-color: var(--bg2);
	border: 2px solid var(--shade1);
	color: var(--lightest);
	font-size: 0.9rem;
/*	max-width: 120px;*/
/*width: 90%;*/
width: fit-content;
padding-left: 1rem;
}

/* help page */

.node-help-menu {
/*	border: 1px solid orange;*/
	margin-left: 2rem;
	max-width: 80ch;
	color: var(--text1);
	text-align: justify;
}

.node-help-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.node-help-menu h1{
	font-family: var(--font2);
	font-size: 1.8rem;
	color: var(--text2);
}

.node-help-menu h4{
	font-family: var(--font2);
	font-size: 1.4rem;
	color: var(--text2);
	margin-bottom: 0.2rem;
}

.node-help-menu strong{
	color: var(--text2);
}