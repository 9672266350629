.upload-page {
	display: flex;
	justify-content: center;
}

.upload-menu {
/*	border: 1px solid red;*/

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: clamp(500px, 600px, 1200px);
}

.upload-header {
	color: var(--text2);
	font-family: var(--font2);
}

.upload-input {
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: var(--lightest);
	width: calc(100% - 4rem);
	height: clamp(300px, 400px, 700px);
	margin: 0 2rem;
	margin-top: 0;
	padding: 0.4rem;
}

.upload-input:focus {
	border: 1px solid var(--text2);
}

.upload-button {
	color: var(--text1);
	padding: 0.2rem 0.4rem;
	border: 4px solid transparent;
}

.upload-button-disabled {
	border: 4px solid var(--bg2);
}

.upload-msg {
/*	border: 1px solid red;*/
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 20px;
	margin: 1rem 0;
}

.upload-msg p {
	margin: 0;
	color: var(--text3);
	font-size: 0.9rem;
}

.upload-msg svg{
	height: 20px;
	width: 20px;
	padding-right: 0.4rem;
}

.upload-icon-success {
	fill: var(--text2);
}

.upload-icon-fail {
	fill: var(--orange);
}