.form-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

/* this is the refactored container meant for all account forms */
.user-form-container {
	/*border: 1px solid red;*/
	display: flex;
	flex-direction: column;

	align-items: center;

	width: fit-content;
	padding: 0 2rem;
}

.form-section {
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	/*border: 1px solid blue;*/
	width: clamp(fit-content, 500px, 100%);
}

.form-section-signin {
	padding-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	/*border: 1px solid blue;*/
	width: clamp(300px, 300px, 100%);
}

.form-section h1, .form-section-signin h1{
	font-family: "ciutadella-semibold";
	color: var(--text2);
	font-size: 2rem;
	/*border: 1px solid orange;*/
	margin:0;
}

.signup-form {
	/*border: 1px solid red;*/
	width: 100%;
/*	display: flex;
	flex-direction: column;
	align-items: center;*/
}

 .signin-form {
 	width:  100%;
 	display: flex;
 	flex-direction: column;
 	align-items: center;
 	/*border: 1px solid red;*/
 }

 .signin-label {
 	text-align: right;
 	width: 40%;
 }

 .signin-input {
 	width:  50%;
 }

 .signin-field {
 	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	/*border: 1px solid green;*/
	color: var(--text1);
	margin: .4rem 0;
 }


.form-field, .form-field-tos {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	/*border: 1px solid green;*/
	color: var(--text1);
	margin: .4rem 0;
}

.form-field {
	/*border: 1px solid red;*/
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.reset-req-form {
	border: 1px solid green;
	width: fit-content;
}



.form-spacer, .form-icons {
	/*border: 1px solid white;*/
	display: flex;
	flex-direction: row;
	width:  13%;
}

.form-icons{
	justify-content: flex-start;
}

.form-label {
	width:  32%;
	/*border: 1px solid orange;*/
	padding-right: 0.5rem;
	color: var(--text1);
}

.form-field label {
	text-align: right;
	margin-right: 0.5rem;
}

.form-field input, .form-field select, .form-field textarea, .signin-field input{
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: white;
	font-family: var(--font1);
}

.form-input, .form-field select{
	width:  40%;
}

.form-input-text {
	width: 70%;
	height: 5rem;
}

.form-field select {
	cursor: pointer;
}

.field-invalid {
	border: 1px solid var(--red) !important;
}

.form-input:focus {
	border: 1px solid var(--text2);
}

.form-field svg {
	fill: var(--text2);
	height:  1.5rem;
}

/*new account explainer text*/

.form-text-toggle-div {
	/*border: 1px solid green;*/
	display: flex;
	justify-content: center;
	font-size: 0.9rem;
	color: var(--text1);
	margin: 0;
	width:  100%;
}

.form-text-div {
	color: var(--text1);
	border: 3px solid var(--light05);
	font-family: var(--font1);
	text-align: justify;
	max-width: 45ch;
	padding: 0 1.4rem;
}

.form-text-div h4 {
	text-align: center;
	font-family: var(--font2);
	font-size: 1.4rem;
	color:  var(--text3);
	margin: 0;
}

.form-text-div li {
	max-width: 36ch;
}

.form-text-div-icon {
	/*border: 1px solid green;*/
	display: flex;
	justify-content: center;
	margin: 0;
	margin-top: 1rem;
	margin-bottom: 0.3rem;
	width: 100%;
	fill: var(--text3);
}

.form-text-div-icon svg {
	height: 2.8rem;
}



.form-checkbox-label {
	width: 80%;
	/*border: 1px solid red;*/
	text-align: center;
}

.form-checkbox {
	width: 20%;
	/*border: 1px solid blue;*/
	/*color: var(--dark-gray);*/
	cursor: pointer;
}

.form-checkbox:before {
	/*border: 1px solid blue;*/
	color: var(--dark-gray);
}

.tos-link {
	color: var(--text2);
	cursor: pointer;
}

.disabled-select-option{
	color:red;
}

select:invalid { color: gray; }

.form-button {
  margin: .5rem;
  width: 60%;
  border-radius: .4rem;
  border: none
}

.reg-new-button {
	max-width: 200px;
}

.form-button h2{
  font-size: 1.2rem;
  margin: .4rem;
}

.valid-form-button {
	background: var(--btn1);
  	cursor: pointer;
}

.valid-form-button h2{
  	color:white;
}

.invalid-form-button {
	background: var(--bg2);
}

.invalid-form-button h2{
	color: var(--bg1);
}

.valid-form-button:hover {
  /*border: 1px solid var(--lt-blue);*/
  background-color: var(--btn1hover);
}

.valid-form-button:active {
  background-color: var(--btn1active);
}

.form-footer {
	color: white;
	font-size: .9rem;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.form-footer-link {
	color: var(--text2);
	cursor: pointer;
	margin-left: .5rem;
}

.form-footer svg {
	height: 2rem;
	fill: var(--orange);
	margin: 0 .5rem;
}

.activate-container{
	display: flex;
	justify-content: center;
}

.activate-button {
	font-family: var(--font2);
	color: var(--text1);
	text-align: center;
	width: clamp(100px, 200px, 250px);
	height:  fit-content;
	padding:  0.5rem;
	margin-top: 0rem;
}

/*styling for account msg pages and text*/

.acct-msg-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 2rem;
	max-width: 500px;
}

.account-h2 {
	font-family: var(--font2);
	color:  var(--text2);
	font-size:  1.8rem;
}

.account-text {
	color: var(--text1);
	font-family: var(--font1);
}

.account-highlight {
	color: var(--text2);
	font-family: var(--font1);
}