.project-main-container {
	display: flex;
	flex-direction: row;
	width: 99%;
	/*border: 1px solid orange;*/
}

.npdc-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1rem;
}

.npdc-panel {
	/*border: 1px solid blue;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: calc(600px - 2rem);
	min-width: 450px;
	width: 600px;
	/*margin-top: 2rem;*/
	/*padding: 2rem;*/
	height: fit-content;
	/*background-color: var(--light05);*/

}

.npdc-section {
	background-color: var(--light05);
	margin: 1rem 0;
	padding: 1rem 1.5rem;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.npdc-group {
	/*background-color: var(--light05);*/
	margin: 1rem 0;
	padding: 1rem 1.5rem;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.npdc-header {
	font-family: var(--font2);
	color: var(--text2);
	text-align: center;
	margin: 0;
}

.npdc-subheader {
	font-family: var(--font2);
	color: var(--text3);
	text-align: left;
	margin: 0;
}

.npdc-groupheader {
	font-family: var(--font2);
	color: var(--text3);
	text-align: left;
	margin: 0;
}

.npdc-text {
	color:  var(--text3);
	text-align: justify;
	font-size: 0.9rem;
	width: 60ch;

	padding: 1rem;
}

.npdc-emph {
	color:  var(--text2);
}

.npdc-textbox {
	margin: 1rem;
	padding: 0 1rem;
	font-size: 0.9rem;
	border:  3px solid var(--light1);
}

.npdc-button {
	width: fit-content;
	color: var(--text1);
	padding: 0.25rem 1rem;
}

.npdc-input-label {
	text-align: right;
	color: var(--text1);
	padding-right: 0.5rem;
}

.npdc-unit-label {
	text-align: left;
	color: var(--text1);
	padding-left: 0.5rem;
}

.npdc-static-data {
	text-align: left;
	color: var(--text1);
	padding-left: 1rem;
	/*font-weight: bold;*/
}

/* table grid for inputs and lables */

.npdc-grid-header {
	text-align: center;
	font-family: var(--font2);
	font-size:  1.1rem;
	color: var(--text2);
	margin-top: 0.5rem;
	margin-bottom: 0.2rem;
}

.npdc-grid-table {
	border-collapse: collapse;
}

.npdc-grid-subsection {
	background-color: var(--light05);
	/*border: 2px solid var(--bg1);*/
}

.npdc-grid-label {
	margin: 0.3rem;
	margin-left:  1rem;
}

.npdc-grid-data {
	margin: 0.3rem;
	margin-right:  1rem;
}


.project-data-pane {
	/*border: 1px solid blue;*/
	flex-grow: 0;
	/*overflow-x: hidenn;*/
	overflow-y: ;
	height: 100%;
	width: calc(100% - 262px);

}

.project-side-panel {
	/*border: 1px solid purple;*/
	overflow-y: auto;
	min-width: 260px;
}

.page-select-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: calc(100% - 1rem);
	padding: 0.3rem;
	border: 1px solid white;

}

.input-flex-container {
	/*border: 1px solid red;*/
	display: flex;
	flex-direction: row;
	justify-content: left;
	width: calc(100% - 4rem);
	max-height: calc(100% - 5rem);
	/*max-width: 800px;*/
	/*max-height: 89%;*/
	margin: 2rem;
	margin-top: 1rem;
	overflow-x: auto;
	overflow-y: auto;
}

.input-flex-title-div {
/*	border: 1px solid pink;*/

	margin-left: 2rem;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.input-flex-title {
	color: var(--text2);
	font-family: var(--font2);
	/*border: 1px solid green;*/
	margin: 0;
	margin-top: 1rem;
}

.flex-table-help-btn {
	margin-left: 1rem;
	height: 1.8rem;
	width: 1.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-table-help-btn svg{
	fill: var(--text1);
	height: 80%;
	width: 80%;
}


/*1-d input table styling*/

.input-1d-table {
	/*width: calc(99% - 4rem);*/
	width: fit-content;
	margin: 0 2rem;
}

.input-1d-row{
	
}

.input-1d-label-div {
	padding-right: 0.5rem;
	/*min-width: fit-content;*/
}

.input-1d-label {
	color: var(--text1);
	font-family: var(--font2);
	text-align: right;
	margin: 0;
	white-space: nowrap;
	/*min-width: fit-content;*/
}

.input-1d-header-label {
	color: var(--text1);
	font-family: var(--font2);
	text-align: center;
	margin: 0;
}

.input-1d-val-div {

}

.input-1d-val {
	background-color: var(--bg2);
	color: var(--text1);
	text-align: center;
	border: 1px solid var(--bg2);
	margin: 0.1rem;
	padding: 0.2rem;
	height: fit-content;
}

/*side panel list styling*/
.cat-box {
	margin: 0.3rem 0.5rem;
}

.cat-box-label {
	padding: 0.1rem;
	cursor: pointer;
	font-family: "ciutadella-semibold";
	background: var(--light1);
	border-radius: .4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
/*	overflow-x: hidden;*/
}

.cat-list-title {
	text-align: center;
	color: var(--text2);
	font-family: "ciutadella-semibold";
	margin: 0.2rem;
}

.cat-list-sub-title {
	text-align: left;
	color: var(--text3);
	font-family: "ciutadella-semibold";
	margin: 0.2rem;
	margin-left: 1rem;
}

.cat-box-label:hover {
  background-color: var(--btn1hover);
}

.cat-box-label:active {
  background-color: var(--btn1active);
}

.cat-box-label h4 {
	color: var(--text3);
	font-family: var(--font2);
	margin: 0.2rem 0;
	white-space: nowrap;
	font-size: 1rem;
	cursor: pointer;
	overflow-x: hidden;
}

.cat-box-list {
	list-style-type: none;
	padding: 0 0.8rem;
	padding-bottom: 0.8rem;
	margin: 0;
}

.cat-box-label-icon {
	height: 1rem;
	width: 1rem;
	padding-right: 0.1rem;
	margin-left: 0.3rem;
}

.cat-box-label-icon svg {
	height: 70%;
	width: 70%;
	fill: var(--text3);
}

.cat-box-spacer {
	height: 0rem;
	margin: 0.6rem 0.5rem;
	width: calc(100% - 1rem);
	border-top: 3px solid var(--light05);
}


.proj-param-list-item {
	cursor: pointer;
	color: var(--text1);
	font-family: var(--font2);
	margin: 0.2rem 0;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.proj-param-list-item-active{
	cursor: default !important;
	color: var(--text2) !important;
}

.proj-param-list-item:hover {
	color: var(--text2);
}

.proj-param-list-item:hover svg {
	fill: var(--text2);
}

.proj-param-list-item-icon {
	height: 1rem;
	width: 1rem;
	padding-right: 0.1rem;
}

.proj-param-list-item-icon svg {
	height: 70%;
	width: 70%;
	fill: var(--text2);
}

.proj-param-list-item-icon-hidden svg {
	height: 70%;
	width: 70%;
	fill: var(--text1);
}

/*altered tables submission list styling*/

.npdc-submit-alt-tables {
	display: flex;
	flex-direction: column;
	background-color: var(--light05);
	padding: 1rem 1.5rem;
	margin: 1rem 0;
	width: calc(100% - 4rem);

	color:  var(--text3);
	font-size: 0.9rem;
	text-align: justify;
}

.npdc-submit-alt-tables-list {
	margin: 0;
}

.npdc-submit-alt-tables-item {

}

.npdc-submit-alt-tables-text {

}

/* help documentation styling */

.input-help-container {
/*	border: 1px solid green;*/
	overflow-y: auto;

	height: 85%;
	padding: 1rem;

	color: var(--text1);
	margin-left: 2rem;
	margin-top: 1rem;

	text-align: justify;
}

.input-help-title {
	color: var(--text3);
	font-family: var(--font2);
	font-size: 1.4rem;
}

.input-help-sum {
	
}

.input-help-data-item {
/*	border: 1px solid red;*/

	margin-bottom: 1rem;
}

.input-help-data-item p{
	margin: 0.2rem 0;
	font-size: 0.92rem;
}

.input-help-data-item-label {
	font-weight: bold;
	color: var(--text3);
	font-family: var(--font2);
	font-size: 1.1rem;
	margin-right: 0.4rem;
}

.input-select-button {
	width: 31%;
	box-sizing: border-box;
}

.input-select-button h2{
	color: var(--text1);
	font-size: 1.1rem !important;
}

.input-select-button-active {
	 background: var(--bg1) !important;
	  color: var(--bg2) !important;
	  cursor: default !important;
	  border: 2px solid var(--text3);
}

.input-select-button-active h2{
	  color: var(--text3) !important;
}

/* Load input form styling */

.npdc-load-form-table {
	width: 90% !important;
/*	border: 1px solid red;*/
}

.npdc-load-form-label{
	text-align: left !important;
}

.npdc-load-form-area {
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: white;
	margin: .2rem;
	padding: 0.2rem;
	width: calc(100% - 10px);
	height: 8rem;
}
