.dl-btn {
	width: 320px;
	padding: 0.15rem;
	font-size: 1.2rem;
	text-align: center;
}

.ui-table {
	color: white;
	/*border: 1px solid green;*/
	/*width: 600px;*/
	border-collapse: separate;
    border-spacing: 0 0.4rem;
    margin-left: 1rem;
    padding-bottom: 1rem;

}

.ui-table tr {
	margin: 20px;
}

.ui-field { 
	text-align: right;
	padding-right: .4rem;
	font-weight: bold;
}

.ui-value {
	padding: 0 3rem;
	background-color: var(--bg2);
}