.status-container {
  width: 100%;
  position:fixed;
  bottom:0;
  height:var(--status-size); 
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: 0;
  
  color: var(--lightest);
  background-color: var(--bg2);
  border-top: 5px solid var(--bg1);
  font-family: "ciutadella-semibold";
}

.status-item {
  margin: 0;
  padding: 0;
  font-size: .9rem;
}

.status-label {
  color: var(--text3);
}

.status-value {
  color: var(--text2);
}

.status-hidden {
  display: none;
}

.status-offline {
  color: var(--text-alert);
}
