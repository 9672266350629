.np-nav-container {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  position: fixed;
  top: var(--nav-size);
  /*height: var(--nav-size);*/
  z-index:9999;
}

.np-nav-container-top {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  height: 44px;
  border-top: 4px solid var(--bg1);
}

.np-nav-container-bottom {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  /*background-color: var(--darkest-gray);*/
  height: 36px;
  border-top: 4px solid var(--bg1);
}

.np-nav-section-pagename {
  /*border: 1px solid red;*/
}

.np-nav-pagename {
  font-size: 1.5rem;
  margin: 0;
  padding: .2rem;
  font-family: "ciutadella-semibold";
  color: var(--text2);
}

.np-nav-menu-button-container {
  display: flex;
  flex: row;
}

.np-nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.np-nav-heading {
  font-family: "ciutadella-semibold";
  color: var(--lightest);
  font-size: 1.5rem;
  margin: 0;
  padding-left: 1rem;
}

.np-nav-value{
  color: var(--lightest);
  font-size: 1.4rem;
  margin: 0;
}

/* Nav Project Name Input Field*/
.np-nav-nameinput {
  background-color: var(--bg1);
  border: 1px solid var(--bg1);
  color: var(--lightest);
  min-width: 300px;
  max-width: 400px;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.2rem;
  margin: 0;
}

.np-nav-nameinput:focus {
  border: 1px solid var(--text2);
}
