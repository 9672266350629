.npdc-nav-container {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  position: fixed;
  top: var(--nav-size);
  /*height: var(--nav-size);*/
  z-index:9999;
}

.npdc-nav-container-top {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  height: 44px;
  border-top: 4px solid var(--bg1);
}

.npdc-nav-container-bottom {
  width: 100%;
  text-align: center;
  /*border: 1px solid red;*/
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  background-color: var(--bg2);
  /*background-color: var(--darkest-gray);*/
  height: 36px;
  border-top: 4px solid var(--bg1);
}

.npdc-nav-section-pagename {
  /*border: 1px solid red;*/
}

.npdc-nav-pagename {
  font-size: 1.5rem;
  margin: 0;
  padding: .2rem;
  font-family: "ciutadella-semibold";
  color: var(--text2);
}

.npdc-nav-menu-button-container {
  display: flex;
  flex: row;
}

.npdc-nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.npdc-nav-heading {
  font-family: "ciutadella-semibold";
  color: var(--lightest);
  font-size: 1.5rem;
  margin: 0;
  padding-left: 1rem;
}

.npdc-nav-value{
  color: var(--lightest);
  font-size: 1.4rem;
  margin: 0;
}

/* Nav Project Name Input Field*/
.npdc-nav-nameinput {
  background-color: var(--bg1);
  border: 1px solid var(--bg1);
  color: var(--lightest);
  min-width: 300px;
  max-width: 400px;
  text-align: center;
  font-size: 1.2rem;
  padding: 0.2rem;
  margin: 0;
}

.npdc-nav-nameinput:focus {
  border: 1px solid var(--text2);
}

/* subnav button styling */

/* shared styling for active and inactive buttons */

.npdc-subnav-btn, .npdc-subnav-btn-active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 65%;

  border-radius: 5px;

  padding: 0 0.5rem;
  margin: 0 0.5rem;

}

.npdc-subnav-btn svg, .npdc-subnav-btn-active svg {
  height: 12px;
  width: 12px;
  margin: 0;
  margin-right: 0.2rem;
  fill: var(--text3);
}

.npdc-subnav-btn h4 , .npdc-subnav-btn-active h4 {
  font-family: var(--font2);
  margin: 0;
}

/* inactive button styling */
.npdc-subnav-btn {
  background-color: var(--shade2);
  border: 2px solid var(--light25);

  cursor: pointer;

  color: var(--text3);
  fill: var(--text3);
}

.npdc-subnav-btn:hover {
  background-color: var(--light1);
}

.npdc-subnav-btn:active {
  background-color: var(--btn1);
}

/* active button styling */
.npdc-subnav-btn-active {
  background-color: var(--btn1);
  border: 2px solid var(--shade15);

  cursor: pointer;

  color: var(--text3);
  fill: var(--text3);
}

/*.npdc-subnav-btn-active:hover {
  background-color: var(--light1);
}

.npdc-subnav-btn-active:active {
  background-color: var(--btn1);
}*/


