.input-table-container {
	margin-right: 4rem;
}

.input-title {
	font-family: "ciutadella-semibold";
	color: var(--text2);
	font-size: 1.2rem;
	/*border: 1px solid blue;*/
	margin-top: 1.5rem;
	margin-bottom: .1rem;
}

.input-table-form {

}

.input-table {

}

.input-table-header {

}

.input-table-header-value {
	font-family: "ciutadella-semibold";
	color: var(--lightest);
	text-align: center;
}

.input-table-row {

}

.input-table-row-label {
	font-family: "ciutadella-semibold";
	color: var(--lightest);
	padding-right: .7rem;
	text-align: right;
}

.input-table-data-cell {

}

.input-table-data-field {
	background-color: var(--bg2);
	border: 1px solid var(--bg2);
	color: var(--lightest);
	max-width: 50px;
	text-align: center;
}

.input-table-data-field:focus {
	border: 1px solid var(--text2);
}