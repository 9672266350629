/*toggle container styling*/

.toggle-section {
	display: flex;
	flex-direction: column;
	/*border: 1px solid var(--bg2);*/
	margin-top: 2rem;
	margin-right: var(--sideMargin1);
}

.toggle-section-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	/*background-color: var(--shade15);*/
	background-color: var(--btn1);
	cursor: pointer;
}

.toggle-section-content {
	padding-left: 1rem;
	background-color: var(--shade05);
}

.toggle-btn {
	/*border: 2px solid var(--text2);*/
	/*height: 3rem;*/
	/*width: 3rem;*/
	/*border-radius: 50%;*/
	display: flex;
	align-items: center;
}

.toggle-btn svg {
	height: 1rem;
	width: 1rem;
	fill:  var(--text3);
	padding: 0 0.5rem;
}

.toggle-section-label {
	color: var(--text3);
	font-family: var(--font1);
	font-size: 1.2rem;
	margin: 0;
	padding: 0.1rem 0;
}

/*HELP toggle container styling*/

.help-toggle-section {
	display: flex;
	flex-direction: column;
	/*border: 1px solid var(--bg2);*/
	margin-top: 0;
	margin-bottom: 1rem;
	margin-right: var(--sideMargin1);
}

.help-toggle-section-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	/*background-color: var(--shade1);*/
	padding-bottom: .5rem;
	cursor: default;
}

.help-toggle-section-content {
	padding-left: 1rem;
	background-color: var(--shade1);
}

.help-toggle-btn {
	/*border: 2px solid var(--text1);*/
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
	background-color: var(--btn1);
	display: flex;
	align-items: center;
	margin-right: .5rem;
	cursor: pointer;
}

.help-toggle-btn svg {
	height: 2rem;
	width: 2rem;
	fill:  var(--text1);
	padding: 0 0.25rem;
}

.help-toggle-section-label {
	color: var(--text1);
	font-family: var(--font1);
	font-size: 1.2rem;
	margin: 0;
	padding: 0;
}