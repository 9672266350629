.model-sub-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/*border: 1px solid orange;*/
	width: calc(100% - 4rem);
	/*width: calc(100% - 8rem);*/
	max-width: 800px;
	margin: 0 2rem;
	margin-bottom: 1rem;
	padding: 0;
	/*background-color: var(--shade1);*/
	/*border: 3px solid var(--light1);*/

	/*width of left color border for models and lists*/
	--model-border:  4px;
	
}

.model-sub-list-head {
	text-align: left;
	width: 100%;
	margin: 0;
	font-family: var(--font2);
	color: var(--text1);
	font-size: 1.4rem;
}

.model-collapsible-btn {
	/*border:  1px solid green;*/
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding: 0.3rem 0;
	width: calc(100% - var(--model-border));
	height: 100%;
	cursor: pointer;
	background-color: var(--light1);
}

.model-collapsible-btn-left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.model-collapsible-btn-left svg {
	height:  1.3rem;
	width:  1.3rem;
	fill: var(--text1);
	margin: 0;
	margin: 0 0.5rem;
	padding:  0;
}

.model-collapsible-btn:hover {
	background-color: var(--light15);
}

.model-collapsible-btn:active{
	background-color: var(--light2);
}

.model-collapsible-content {
	/*border:  1px solid green;*/
	background-color: var(--light05);
	width: 100%;
}

/*model count styling*/
.model-list-count-container {
	margin: 0;
	margin-right: 0.8rem;
	height: 2rem;
	width: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 1rem;
	background-color:  var(--bg1);
}

.model-list-count {
	font-family: var(--font2);
	color: var(--text1);
	font-size: 1.2rem;
	margin: 0;
}

.count-solved {
	border:2px solid var(--modelSolved);
}

.count-queued {
	border:2px solid var(--modelQueued);
}

.count-failed {
	border:2px solid var(--modelFailed);
}

.count-expired {
	border:2px solid var(--modelExp);
}

.count-deleted {
	border:2px solid var(--modelDel);
}

/* color left-border on model list toggle btn*/

.list-toggle-solved {
	border-left:var(--model-border) solid var(--modelSolved);
}

.list-toggle-queued {
	border-left:var(--model-border) solid var(--modelQueued);
}

.list-toggle-failed {
	border-left:var(--model-border) solid var(--modelFailed);
}

.list-toggle-expired {
	border-left:var(--model-border) solid var(--modelExp);
}

.list-toggle-deleted {
	border-left:var(--model-border) solid var(--modelDel);
}